import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({}, {
      metaReducers: [],
      runtimeChecks: {
        strictActionWithinNgZone: true,
        strictActionImmutability: false,
        strictStateImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot([]),
  ],
  providers: []
})
export class NgrxCoreModule {
}
