import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import qs from 'qs';
import { Observable, concatMap, map } from 'rxjs';

export interface MarkupRoomParticipant {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface participantObjetct {
  add: boolean;
  remove: boolean;
  edit: boolean;
}

export interface documentsObject {
  rename: boolean;
  advancedEdit: boolean;
}

export interface idvObject {
  kba: boolean;
  credentialAnalysis: boolean;
}

export interface authenticationObject {
  personalPassword: boolean;
  twoFactor: boolean;
}

export interface MarkupRoomConfig {
  idv: idvObject,
  authentication: authenticationObject,
  documents: documentsObject,
  participants: participantObjetct,
  status: string,
  organizationId: string,
}

export interface MarkupRoomData {
  config?: MarkupRoomConfig;
  participants?: MarkupRoomParticipant[],
  documents?: string[],
  senderId: string,
  redirectUrl?: string,
}

export interface MarkupRoomCreateRequest extends MarkupRoomData {
  senderId: string;
  organizationId: string;
}

export interface MarkupRoomCreateResponse {
  url: string;
  config: MarkupRoomConfig;
}

export interface EsignFromMarkupRoomRequest {
  markupRoomId: string;
  documents: any[];
  participants: any[];
  senderId: string;
}

@Injectable({
  providedIn: 'root',
})
export class MarkupRoomService {
  constructor(private http: HttpClient) {}

  createMarkupRoom(markupPayload: MarkupRoomCreateRequest) {
    return this.http.post<MarkupRoomCreateResponse>(`/api/markup-room`, markupPayload);
  }

  getMarkupRoom(id: string) {
    return this.http.get<MarkupRoomData>(`/api/markup-room/${id}`);
  }

  createEsignFromMarkupRoom(payload: EsignFromMarkupRoomRequest) {
    return this.http.post<any>('/api/esign/create-esign-from-markup-room', payload);
  }

  getUploadUrl(
    organizationId: string,
    markupRoomId: string,
    fileName: string,
    contentType = 'application/pdf',
    documentId: string,
  ) {
    const q = qs.stringify({ organizationId, markupRoomId, fileName, contentType, documentId });
    return this.http.get<{ url: string, id: string, gcsRefId: string, fileName: string }>(`/api/markup-room/upload-url?${q}`);
  }

  getReadUrl(markupRoomId: string, gcsRefId: string) {
    const q = qs.stringify({ markupRoomId, gcsRefId });
    return this.http.get<{ url: string, gcsRefId: string }>(`/api/markup-room/signed-url?${q}`)
  }

  uploadToGcs(url: string, file: File) {
    return this.http.put(url, file, {
      reportProgress: true,
      observe: 'events',
      headers: {
        'content-type': file.type,
      }
    });
  }

  handleUpload(file: File, organizationId: string, markupRoomId: string, documentId: string) {
    return this.getUploadUrl(organizationId, markupRoomId, file.name, file.type, documentId)
      .pipe(
        concatMap((uploadUrlData) => {
          const { url, gcsRefId } = uploadUrlData;

          return new Observable((obs) => {
            this.uploadToGcs(url, file)
              .subscribe((val) => {
                if ((val as any).status) {
                  obs.next(val);
                  obs.complete();
                }
              });
          })
            .pipe(
              concatMap(() => {
                return this.getReadUrl(markupRoomId, gcsRefId)
                  .pipe(
                    map((urlData) => {
                      return {
                        ...uploadUrlData,
                        ...urlData,
                      }
                    })
                  );
              })
            )
        }),
      );
  }
}
